import React from 'react'
import { cvData } from './cvData'
import { Section } from './section'

import './cv.scss'
import { StaticImage } from 'gatsby-plugin-image'

type Props = {
  title?: string;
  tagline?: string;
}

export const CvViewer:React.FC<Props> = ({ title = '', tagline = '' }: Props) => (
  <div className="cv">
    <header>
        <h1>{title}</h1>
        <h2>{tagline}</h2>
    </header>
    <section className="summary">
      <h1>Summary</h1>
      <p>{cvData.summary}</p>
    </section>
    {cvData.sections.map(section => <Section data={section} />)}
  </div>
)
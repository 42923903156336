import React, { ReactNode } from 'react'

import './layout.css'

type Props = {
    children: ReactNode[];
}

export const Layout: React.FC<Props> = ({ children }: Props) => (
  <div>
    <main>{children}</main>
    <footer>
      © {new Date().getFullYear()} Ian Bourke, Built with
      {' '}
      <a href='https://www.gatsbyjs.com'>Gatsby</a>
    </footer>
  </div>
)

import React from 'react'
import { CvSection } from './cvData'
import { Entry } from './entry'

type Props = {
  data: CvSection;
}

export const Section:React.FC<Props> = ({ data }: Props) => (
  <section className="section">
    <h1>{data.title}</h1>
    {data.entries.map(entry => <Entry data={entry} />)}
  </section>
)
export type CvEntry = {
  title: string;
  description: string[];
  tags: string[];
  info?: string;
  startDate?: Date;
  endDate?: Date;
}

export type CvSection = {
  title: string;
  entries: CvEntry[];
}

export type CV = {
  summary: string;
  sections: CvSection[];
}

export const cvData: CV = {
  summary: 'Polyglot senior/lead full-stack developer. Currently using TypeScript, React & Node. Extensive experience with TDD and Agile methods, UI/UX & design systems. Experience in finance, energy, retail and telecoms.',
  sections: [
    {
      title: 'Experience',
      entries: [
        {
          title: 'Kiwi Power',
          startDate: new Date('2016-11-30T00:00:00Z'),
          tags: [
              'TypeScript',
              'JavaScript',
              'React',
              'Redux',
              'Jest',
              'Enzyme',
              'NodeJS',
              'Storybook',
              'Chromatic',
              'Webpack',
              'InfluxDB',
              'AWS',
              'Squirrel',
          ],
          description: [
              'Lead front-end developer on Kiwi Core - Kiwi Power’s SaaS Virtual Power Plant platform. This enabled both energy utility company and individual asset owners to easily integrate their assets onto the platform and manage their participation in a variety of applicable energy programmes. The platform operated across Europe and the US and provided solutions in many programmes such as secondary and tertiary reserve, peak avoidance, frequency response etc.',
              'It was built as a responsive, themed SPA with a large amount of data visualisation. Developed using React, TypeScript, Redux, Recharts, D3 and SVG.',
              'Contributed to a number of back-end services that made up Kiwi Core, written in NodeJS and Scala and sole developer of an in-house CRM solution which was used to manage the sales and installation pipelines. Written using React and NodeJS.',
              'Responsibilities included team leadership and UI design, contributing to Kiwi’s organisation-wide design system.',
              'Wrote and open-sourced a Squirrel unit testing framework (https://github.com/kiwipower/nutkin).'
            ]
        },
        {
          title: 'Credit Suisse',
          startDate: new Date('2012-05-01T00:00:00Z'),
          endDate: new Date('2016-11-29T00:00:00Z'),
          tags: [
            'JavaScript',
            'NodeJS',
            'Backbone',
            'React',
            'Redux',
            'Mongo',
            'Redis',
            'Webdriver',
            'Karma',
            'Java',
          ],
          description: [
            'Developer on the global equities strategic risk platform, primarily on the trading UI but also on the core risk co-ordination system. I also solely developed an Internet facing web app to facilitate investing in Credit Suisse ISA products via building societies',
            'The trading UI was an event driven NodeJS aggregating intraday risk data in custom pivot tables. JavaScript on both client and server side, using a mix of Backbone.js, React, websockets, NodeJS, MongoDB and Redis. Small team of four developers for the whole application with a weekly release cycle. ',
            'The core risk system was Java based and managed many external systems and asynchronous/parallel computations. Technologies included Java 7, Elastic search, Hazelcast and JSON over HTTP.',
          ]
        }
      ]
    },
    {
      title: 'Education',
      entries: [
        {
          title: 'Imperial College of Science, Technology & Medicine',
          info: 'MEng Computing (Software Engineering), 1st class honours',
          startDate: new Date('1995-09-01T00:00:00Z'),
          endDate: new Date('1999-06-01T00:00:00Z'),
          tags: [],
          description: []
        }
      ]
    }
  ]
}
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import { Layout } from '../components/layout'
import { Seo } from '../components/seo'
import { HeroPanel } from '../components/heroPanel'
import { CvViewer } from '../components/cvViewer'
import { StaticImage } from 'gatsby-plugin-image'

const CvPage = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          tagline
        }
      }
    }
  `)

  return (
    <Layout>
      <CvViewer title={data.site.siteMetadata?.title} tagline={data.site.siteMetadata?.tagline}/>
      <></>
    </Layout>
  )
}

export default CvPage

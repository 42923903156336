import React from 'react'
import { format } from 'date-fns'
import { CvEntry } from './cvData'
import { Tag } from './tag'

type Props = {
  data: CvEntry;
}

export const Entry:React.FC<Props> = ({ data }: Props) => (
  <div className="entry">
    <aside className={`info ${data.description.length === 0 ? 'wide' : ''}`}>
      <h2>{data.title}</h2>
      {data.startDate && (
        <div className="dates">
          <span className="date">{format(data.startDate, 'MMM yyyy')}</span>
          <> - <span className="date">{data.endDate ? format(data.endDate, 'MMM yyyy') : 'Present'}</span></>
        </div>
      )}
      {data.info && <div className="location">{data.info}</div>}
    </aside>
    {data.description.length > 0 && <div className="details">
      {data.description.map(desc => <p>{desc}</p>)}
      {data.tags.length > 0 &&
      <div className="tags">
        {data.tags.map(tag => <Tag label={tag}/>)}
      </div>
      }
    </div>}
  </div>
)